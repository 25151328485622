import React from 'react';

export default function MoreSelectedBadge({ items }) {
	const title = items.join(', ');
	const { length } = items;
	const label = `${length} geselecteerd`;

	return (
		<span title={title} className="rounded-pill text-bg-primary px-2 py-1">
			{label}
		</span>
	);
}
