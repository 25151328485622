import React from 'react';
import { components } from 'react-select';

function TemplatedSelectPickerMultiValueRemove({ innerProps: { onClick, ...innerProps }, ...props }) {
	const customOnClick = event => {
		event.preventDefault();
		event.stopPropagation();
		onClick(event);
	};

	return <components.MultiValueRemove innerProps={{ ...innerProps, onClick: customOnClick }} {...props} />;
}

export default TemplatedSelectPickerMultiValueRemove;

