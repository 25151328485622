/* global global */
// JSZip is global requirement of the HTML5 buttons
import jszip from 'jszip';

import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.css';
import 'datatables.net-buttons/js/buttons.html5.js';
import moment from 'moment';

const formatNumber = require('../formatNumber.js');

global.JSZip = jszip;

/**
 * This plug-in for DataTables represents the ultimate option in extensibility
 * for sorting date / time strings correctly. It uses
 * [Moment.js](http://momentjs.com) to create automatic type detection and
 * sorting plug-ins for DataTables based on a given format. This way, DataTables
 * will automatically detect your temporal information and sort it correctly.
 *
 * For usage instructions, please see the DataTables blog
 * post that [introduces it](//datatables.net/blog/2014-12-18).
 *
 * @name Ultimate Date / Time sorting
 * @summary Sort date and time in any format using Moment.js
 * @author [Allan Jardine](//datatables.net)
 * @depends DataTables 1.10+, Moment.js 1.7+
 *
 * @example
 *    $.fn.DataTable.moment( 'HH:mm MMM D, YY' );
 *    $.fn.DataTable.moment( 'dddd, MMMM Do, YYYY' );
 *
 *    $('#example').DataTable();
 */
$.fn.DataTable.moment = function (format, locale) {
	const types = $.fn.DataTable.ext.type;

	// Add type detection
	types.detect.unshift(d => {
		let value = d;
		// Strip HTML tags if possible
		if(value && value.replace) {
			value = value.replace(/<.*?>/g, '');
		}

		// Null and empty values are acceptable
		if(value === '' || value === null) {
			return `moment-${format}`;
		}

		return moment(value, format, locale, true).isValid()
			? `moment-${format}`
			: null;
	});

	// Add sorting method - use an integer for the sorting
	types.order[`moment-${format}-pre`] = function (d) {
		let value = d;
		if(value && value.replace) {
			value = value.replace(/<.*?>/g, '');
		}
		return value === '' || value === null
			? -Infinity
			: parseInt(moment(value, format, locale, true).format('x'), 10);
	};
};

/**
 * Make the date columns in datatables properly sortable
 */
$.fn.DataTable.moment('DD-MM-YYYY');
$.fn.DataTable.moment('DD-MM-YYYY H:mm');
$.fn.DataTable.moment('DD-MM-YYYY H:mm:ss');

// DataTables sorting plugin for numeric numbers with comma
// https://datatables.net/plug-ins/sorting/numeric-comma
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
	'numeric-comma-pre': function (a) {
		const x = (a == '-') ? 0 : a.replace(/,/, '.');
		return parseFloat(x);
	},

	'numeric-comma-asc': function (a, b) {
		return ((a < b) ? -1 : ((a > b) ? 1 : 0));
	},

	'numeric-comma-desc': function (a, b) {
		return ((a < b) ? 1 : ((a > b) ? -1 : 0));
	},
});

/**
 * Set defaults for DataTables
 */
$.extend(true, $.fn.DataTable.defaults, {
	language: {
		url: '/packages/DataTables/1.10.12/json/dutch.json',
	},
	layout: {
		bottomEnd: {
			paging: {
				firstLast: false
			}
		}
	},
	columnDefs: [
		{
			targets: 'currency',
			render(data) {
				return (`€ ${parseFloat(formatNumber.default(data)).toFixed(2)}`).replace('.', ',');
			},
			fnCreatedCell(nTd, sData) {
				if(sData < '0') {
					$(nTd).css('color', 'red');
				}
			},
		},
		{
			targets: 'no-display',
			visible: false,
		},
	],
});
