import axios from 'axios';

async function createFetch({
	data = null,
	handle = null,
	headers = {},
	method = 'get',
	params = {},
	url = '',
	withCredentials = true,
	source,
	extraOptions = {}
}) {
	const requestHeaders = {
		'content-type': 'application/json',
		...(withCredentials && { credentials: 'include' }),
		...(handle !== null && { 'X-Dation-Handle': handle }),
		...headers
	};

	const instance = axios.create({ headers: requestHeaders });

	return instance.request({
		method,
		params,
		url,
		withCredentials,
		data: data ? JSON.stringify(data) : null,
		cancelToken: source ? source.token : null,
		...extraOptions
	});
}

export default createFetch;
