import React from 'react';
import { components } from 'react-select';
import MoreSelectedBadge from './TemplatedSelectPickerMoreBadge';

export default function TemplatedSelectPickerMultiValue({
	maxToShow, index, getValue, children, ...props
}) {
	if(getValue().length <= maxToShow) {
		return <components.MultiValue index={index} getValue={getValue} {...props}>{children}</components.MultiValue>;
	}

	if(index === 0) {
		return <MoreSelectedBadge items={getValue().map(({ label }) => label)} />;
	}
	return null;
}

