import React from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import TemplatedSelectPicker from '../../react/curious-ui/TemplatedComponents/TemplatedSelectPicker';

// This is a replacement for the unmaintained bootstrap select library.
// In order to use this in tpl/twig files, you need a div with the class `selectpickerContainer`
// In there define the select and options.

// See the useExternalEvents hook for how to interact with the select picker through events.
//
// at the moment the following data-* props are supported on the select element
// * width: css width applied to the control, i.e. '200px'
// * header: used as a placeholder when no options are selected
// * liveSearch: enable search functionality
// * uniqueOptionValue: If the option with this value is selected, no others may be selected
// * selectAll: Adds a `select all` option
// * selectAllValue: specific value for the select all option that should be used as a value instead of all the available options
// * maxSelected: How many chips to show before showing 'x selected', default 3
//   The following data-* props can be used for live querying the back end on search input
// * queryUrl: url for live searching the backend for options
// * queryHandle: X-Dation-Handle value to be put on the search query headers
// * querySearchKey: search term for the option query
// * queryValueKey: mapping key for the query result to the option value
// * queryLabelKey: mapping key for the query result to the option label
// * queryExtraParams: extra json parameters for the option query

// For options, the following data-* attributes are supported:
// * prepend: html to be prepended before the option label

function renderSelectPickers() {
	const selectPickerContainers = document.querySelectorAll('.selectpickerContainer');
	selectPickerContainers.forEach(selectPickerNode => {
		renderSelectPicker(selectPickerNode);
	});
}

function renderSelectPicker(selectPickerNode) {
	const select = selectPickerNode.querySelector('select');
	if(select) {
		unmountComponentAtNode(selectPickerNode);
		ReactDOM.render(<TemplatedSelectPicker node={select} />, selectPickerNode)
	}
}

window.addEventListener('RENDER_SELECT_PICKER', event => {
	const { detail: { querySelector }} = event;
	const selectPickerNode = document.querySelector(querySelector);
	renderSelectPicker(selectPickerNode.parentNode);
});

$(document).ready(renderSelectPickers);
$(document).ajaxComplete(renderSelectPickers);
