import { components } from 'react-select';
import React from 'react';

export default function TemplatedSelectPickerOption({
	data, isSelected, children, selectProps, innerProps, ...props
}) {
	const { instanceId } = selectProps;

	const checkmark = isSelected
		? <span className="glyphicons glyphicons-check " />
		: null;
	const prepend = data?.data?.prepend
		? <div dangerouslySetInnerHTML={{ __html: data.data.prepend }} className="me-2" />
		: null;

	return (
		<components.Option
			data={data}
			isSelected={false}
			innerProps={{
				...innerProps,
				id: `react-select-${instanceId}-option-${data.value}`
			}}
			selectProps={selectProps}
			{...props}
		>
			<div className="d-flex align-items-center justify-content-between">
				<div className="d-flex align-items-center">
					{prepend}
					{children}
				</div>
				{checkmark}
			</div>
		</components.Option>
	);
}
