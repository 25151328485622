import { components } from 'react-select';
import React from 'react';

export default function TemplatedSelectPickerSingleValue({ children, ...props }) {
	const value = props.getValue()[0];
	if(value.data?.prepend) {
		return (
			<components.SingleValue {...props}>
				{/* eslint-disable-next-line react/no-danger */}
				<div dangerouslySetInnerHTML={{ __html: `${value.data.prepend} ${value.label}` }} />
			</components.SingleValue>
		);
	}
	return (
		<components.SingleValue {...props}>
			{children}
		</components.SingleValue>
	);
}
