import { useState } from 'react';

export default identifier => {
	const [externalChangeEventValues, setExternalChangeEventValues] = useState(null);
	const [updateOptionsEventValues, setUpdateOptionsEventValues] = useState(null);
	const [addAndSelectEventValue, setAddAndSelectEventValue] = useState(null);
	const [isDisabledEventValue, setIsDisabledEventValue] = useState(false);

	window.addEventListener(`change_${identifier}`, event => {
		const { detail: selectedValues } = event;
		setExternalChangeEventValues(selectedValues);
	});

	window.addEventListener(`update_options_${identifier}`, event => {
		const { detail: newOptions } = event;
		setUpdateOptionsEventValues(newOptions);
	});

	window.addEventListener(`change_isDisabled_${identifier}`, event => {
		const { detail: isDisabled } = event;
		setIsDisabledEventValue(isDisabled);
	});

	window.addEventListener(`add_and_select_new_option_${identifier}`, event => {
		const { detail: newOption } = event;
		setAddAndSelectEventValue(newOption);
	});

	const dispatchValueChangedEvent = (newValue, oldValue) => {
		window.dispatchEvent(new CustomEvent(`${identifier}_changed`, {
			detail: {
				newValue,
				oldValue
			}
		}));
	};

	return {
		externalChangeEventValues, updateOptionsEventValues, addAndSelectEventValue, dispatchValueChangedEvent, isDisabledEventValue, setIsDisabledEventValue
	};
};
